const binding = { modules: {}, dataActions: {} };

    (binding.modules['ext-country-picker'] = {
        c: () => require('partner/modules/ext-country-picker/ext-country-picker.tsx'),
        $type: 'contentModule',
        da: [],
        
        iNM: false,
        ns: '__local__',
        n: 'ext-country-picker',
        p: '__local__',
        
        pdp: '',
        
        
        md: 'src/modules/ext-country-picker'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|modules|ext-country-picker|ext-country-picker'] = {
                c: () => require('partner/modules/ext-country-picker/ext-country-picker.view.tsx'),
                cn: '__local__-__local__-ext-country-picker'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };